<template>
  <svg :width="width" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8529 0H1.14706C0.514571 0 0 0.473133 0 1.05469V4.92188C0 5.50343 0.514571 5.97656 1.14706 5.97656H3.26274V10.9453C3.26274 11.5269 3.77732 12 4.4098 12H8.5902C9.22268 12 9.73725 11.5269 9.73725 10.9453V5.97656H11.8529C12.4854 5.97656 13 5.50343 13 4.92188V1.05469C13 0.473133 12.4854 0 11.8529 0ZM4.4098 11.2969C4.19897 11.2969 4.02745 11.1392 4.02745 10.9453L4.02748 9.42628V2.22656H3.26274V5.27344H1.14706C0.936229 5.27344 0.764706 5.11573 0.764706 4.92188V1.05469C0.764706 0.860836 0.936229 0.703125 1.14706 0.703125H11.8529C12.0638 0.703125 12.2353 0.860836 12.2353 1.05469V4.92188C12.2353 5.11573 12.0638 5.27344 11.8529 5.27344H9.73725V2.22656H8.97257L8.97255 9.42628V10.9453C8.97255 11.1392 8.80103 11.2969 8.5902 11.2969H5.26283H4.4098Z"
      :fill="fillColor"
    />
    <rect x="1.61206" y="1.55273" width="9.8266" height="0.705874" rx="0.352937" :fill="fillColor" />
    <path
      d="M6.50017 7.47656C7.38565 7.47656 8.10605 6.81417 8.10605 6C8.10605 5.18583 7.38565 4.52344 6.50017 4.52344C5.61469 4.52344 4.89429 5.18583 4.89429 6C4.89429 6.81417 5.61469 7.47656 6.50017 7.47656ZM6.50017 5.22656C6.96399 5.22656 7.34135 5.57353 7.34135 6C7.34135 6.42647 6.96399 6.77344 6.50017 6.77344C6.03635 6.77344 5.65899 6.42647 5.65899 6C5.65899 5.57353 6.03635 5.22656 6.50017 5.22656Z"
      :fill="fillColor"
    />
    <path
      d="M6.7125 8.70769C6.51431 8.58774 6.26238 8.65988 6.16289 8.83449C6.01298 9.10404 6.26017 9.39893 6.57482 9.34472C6.91122 9.28029 6.99259 8.88179 6.7125 8.70769Z"
      :fill="fillColor"
    />
    <path
      d="M6.12515 3.06865C6.16751 3.25632 6.36078 3.38161 6.57479 3.34475C6.78213 3.30502 6.91458 3.12537 6.87507 2.93131C6.82692 2.72002 6.59819 2.6051 6.3892 2.66365C6.19354 2.72107 6.08931 2.89259 6.12515 3.06865Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'MoneySVG',

  props: {
    width: {
      type: Number,
      default: 13,
    },
    fillColor: {
      type: String,
      default: 'grey',
    },
  },
}
</script>
